/*  */
/* Brand services */
/*  */

import { app, getDBInstance, ObjectId } from "../dbServices";

export const addServiceCategory = async ({ values, isActive, image }: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!values) {
    return;
  }
  const result = await getDBInstance()
    .collection("autoDetailingCategories")
    .insertOne({
      name: {
        en: values.name,
      },
      isActive,
      image: image ? image : {},
      dateCreated: new Date(),
      dateModified: null,
      // order: values.order,
    });
  return result;
};

export const updateServiceCategory = async ({
  id,
  values,
  isActive,
  image,
}: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id || !values) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("autoDetailingCategories")
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          name: {
            en: values.name,
          },
          isActive,
          image: image ? image : {},
          dateModified: new Date(),
          // order: values.order,
        },
      }
    );

  return result;
};

export const getServiceCategories = async ({
  page = 0,
  rowsPerPage = 10,
}: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const totalDocumentCount = await getDBInstance()
    .collection("autoDetailingCategories")
    .count({});

  const result = await getDBInstance()
    .collection("autoDetailingCategories")
    .aggregate([
      {
        $match: {},
      },
      {
        $sort: {
          _id: -1,
        },
      },
      { $skip: skip },
      { $limit: limit },
      {
        $project: {
          _id: 1,
          name: 1,
          isActive: 1,
          dateCreated: 1,
          dateModified: 1,
          // order: 1,
          image: {
            url: 1,
            name: 1,
          },
        },
      },
    ]);

  return { data: result, totalDocumentCount };
};

export const getOneServiceCategory = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("autoDetailingCategories")
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);
  return result[0];
};

export const DeleteServiceCategory = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("autoDetailingCategories")
    .deleteOne({ _id: id });

  return result;
};
