import React, { useState } from 'react';
import { Field } from 'formik';

export const WorkingHoursSection = ({ daysOfWeek, setFieldValue, values }: any) => {
  const [customTime, setCustomTime] = useState({ start: '', end: '' });

  const handleCustomTimeChange = (field: any, value: any) => {
    setCustomTime(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const applyCustomTime = () => {
    if (customTime.start && customTime.end) {
      daysOfWeek.forEach((day: any) => {
        setFieldValue(`workingHours.${day.toLowerCase()}`, {
          startTime: customTime.start,
          endTime: customTime.end,
        });
      });
    }
  };

  const clearAllTimes = () => {
    daysOfWeek.forEach((day: any) => {
      setFieldValue(`workingHours.${day.toLowerCase()}`, {
        startTime: "",
        endTime: "",
      });
    });
  };

  return (
    <div className="space-y-6">
      {/* Custom Time Setter */}
      <div className="rounded-lg border border-gray-200 p-4 dark:border-navy-700">
        <h4 className="mb-4 text-sm font-medium text-navy-700 dark:text-white">Set Custom Hours</h4>
        <div className="grid grid-cols-1 sm:grid-cols-[1fr,auto,1fr,auto] gap-4 items-center">
          <div className="relative">
            <input
              type="time"
              value={customTime.start}
              onChange={(e) => handleCustomTimeChange('start', e.target.value)}
              className="w-full appearance-none rounded-lg border border-gray-200 bg-white px-4 py-2.5 text-sm transition-colors focus:border-brand-500 focus:outline-none dark:border-navy-600 dark:bg-navy-700"
            />
            <label className="absolute -top-2 left-2 bg-white px-1 text-xs text-gray-500 dark:bg-navy-800 dark:text-gray-400">
              Opening Time
            </label>
          </div>

          <div className="hidden sm:flex w-8 items-center justify-center">
            <div className="h-[2px] w-full bg-gray-200 dark:bg-navy-700"></div>
          </div>

          <div className="relative">
            <input
              type="time"
              value={customTime.end}
              onChange={(e) => handleCustomTimeChange('end', e.target.value)}
              className="w-full appearance-none rounded-lg border border-gray-200 bg-white px-4 py-2.5 text-sm transition-colors focus:border-brand-500 focus:outline-none dark:border-navy-600 dark:bg-navy-700"
            />
            <label className="absolute -top-2 left-2 bg-white px-1 text-xs text-gray-500 dark:bg-navy-800 dark:text-gray-400">
              Closing Time
            </label>
          </div>

          <button
            type="button"
            onClick={applyCustomTime}
            disabled={!customTime.start || !customTime.end}
            className="rounded-lg bg-brand-500 px-4 py-2 text-sm font-medium text-white transition-colors hover:bg-brand-600 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Apply to All Days
          </button>
        </div>
      </div>

      {/* Time Selection Grid */}
      <div className="overflow-hidden rounded-xl border border-gray-200 bg-white dark:border-navy-700 dark:bg-navy-800">
        <div className="flex justify-end p-4">
          <button
            type="button"
            onClick={clearAllTimes}
            className="flex items-center gap-2 rounded-lg bg-red-50 px-4 py-2 text-sm font-medium text-red-600 transition-all hover:bg-red-100 dark:bg-red-900/30 dark:text-red-400"
          >
            <span className="material-icons-outlined text-lg">clear</span>
            Clear All Times
          </button>
        </div>

        {daysOfWeek.map((day: any, index: any) => (
          <div
            key={day}
            className={`grid grid-cols-1 sm:grid-cols-[150px,1fr] gap-4 p-4 ${
              index !== daysOfWeek.length - 1
                ? "border-t border-gray-200 dark:border-navy-700"
                : ""
            }`}
          >
            <div className="font-medium text-navy-700 dark:text-white self-center">
              {day}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-[1fr,auto,1fr] gap-4 items-center">
              <div className="relative">
                <Field
                  type="time"
                  name={`workingHours.${day.toLowerCase()}.startTime`}
                  className="w-full appearance-none rounded-lg border border-gray-200 bg-white px-4 py-2.5 text-sm transition-colors focus:border-brand-500 focus:outline-none dark:border-navy-600 dark:bg-navy-700"
                />
                <label className="absolute -top-2 left-2 bg-white px-1 text-xs text-gray-500 dark:bg-navy-800 dark:text-gray-400">
                  Opening Time
                </label>
              </div>

              <div className="hidden sm:flex w-8 items-center justify-center">
                <div className="h-[2px] w-full bg-gray-200 dark:bg-navy-700"></div>
              </div>

              <div className="relative">
                <Field
                  type="time"
                  name={`workingHours.${day.toLowerCase()}.endTime`}
                  className="w-full appearance-none rounded-lg border border-gray-200 bg-white px-4 py-2.5 text-sm transition-colors focus:border-brand-500 focus:outline-none dark:border-navy-600 dark:bg-navy-700"
                />
                <label className="absolute -top-2 left-2 bg-white px-1 text-xs text-gray-500 dark:bg-navy-800 dark:text-gray-400">
                  Closing Time
                </label>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Copy Hours Feature */}
      <div className="flex flex-wrap items-center gap-3">
        <span className="text-sm font-medium text-gray-600 dark:text-gray-400">
          Copy hours from:
        </span>
        <select
          className="rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm focus:border-brand-500 focus:outline-none dark:border-navy-600 dark:bg-navy-700"
          onChange={(e) => {
            const sourceDay = e.target.value;
            if (sourceDay) {
              const sourceHours = values.workingHours[sourceDay.toLowerCase()];
              daysOfWeek.forEach((day: any) => {
                if (day.toLowerCase() !== sourceDay.toLowerCase()) {
                  setFieldValue(`workingHours.${day.toLowerCase()}`, {
                    ...sourceHours,
                  });
                }
              });
            }
          }}
        >
          <option value="">Select day</option>
          {daysOfWeek.map((day: any) => (
            <option key={day} value={day.toLowerCase()}>
              {day}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default WorkingHoursSection;