import {
  deleteDetailingService,
  addDetailingService,
  updateDetailingService,
} from "controller/services/AutoDetailing/detailingServices";
import React, { useContext } from "react";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { AlertContext } from "controller/context/alertContext";
import { formatImage } from "controller/common/formatImage";
import {
  useDetailingCategories,
  useDetailingService,
  useOneDetailingService,
} from "model/queryCalls/queryCalls";

export const useServices = () => {
  const { id } = useParams();
  // const branchId = branchId
  const { setAlert } = useContext(AlertContext);
  // const navigate = useNavigate();
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [serviceImage, setServiceImage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const fileInputRef = React.useRef(null); // Use ref for file input
  const [isActive, setIsActive] = React.useState(true);
  // const [selectedPlugs, setSelectedPlugs] = React.useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [selectedPlugsData, setSelectedPlugsData] = React.useState([]);
  const [selectedCategoryData, setSelectedCategoryData] = React.useState();
  // const [plugOptions, setPlugOptions] = React.useState([]);
  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [isVisible, setIsVisible] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState();
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedService, setSelectedService] = React.useState();
  const [branchId, setBranchId] = React.useState<any>();

  const { data: categoryData } = useDetailingCategories({
    page: 0,
    rowsPerPage: 1000,
  });
  const { data: serviceData, isSuccess } = useOneDetailingService({
    id: selectedService,
  });
  const { refetch } = useDetailingService({ page, rowsPerPage, branchId });
  React.useEffect(() => {
    getBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBranch = async () => {
    const id = await localStorage.getItem("branchId");
    setBranchId(id);
    refetch();
  };

  React.useEffect(() => {
    const categoryOption =
      categoryData &&
      categoryData?.data?.map((item: any) => ({
        value: item._id,
        label: item.name.en,
      }));

    setCategoryOptions(categoryOption);
    setSelectedCategoryData(
      categoryOption &&
        categoryOption.find(
          (option: any) =>
            option.value.toString() === serviceData?.category.toString()
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryData, serviceData]);

  const onCategoryChange = (selectedOption: any) => {
    setSelectedCategoryData(selectedOption);
    setSelectedCategory(selectedOption ? selectedOption.value : null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const initialValues: any = {
    name: "",
    description: "",
    image: "",
    order: 1,
    startingPrice: 0,
    isActive: true,
    // efficiency: 1,
    // range: 1,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    description: Yup.string().required("Please enter description"),
    startingPrice: Yup.number().required("Please enter starting price"),
    // efficiency: Yup.number().required("Please enter efficiency"),
    // range: Yup.number().required("Please enter range"),
    order: Yup.number().required("Please add vehicle order"),
  });

  const onSubmit = async (values: any) => {
    console.log("selectedcategory", selectedCategory);
    setLoading(true);
    try {
      const params = {
        values,
        isActive,
        image: serviceImage
          ? formatImage({ file: serviceImage, source: "imageKit" })
          : null,
        category: selectedCategory,
        branchId: branchId,
        // plugs: selectedPlugs,
      };

      if (!selectedCategory) {
        setAlert({
          active: true,
          type: "error",
          message: "Please add Category",
        });
        return;
      }
      if (selectedService) {
        const result = await updateDetailingService({
          id: selectedService,
          ...params,
        });
        setLoading(false);

        console.log("insertedId", result);
        setAlert({
          active: true,
          type: "success",
          message: "Service Updated successfully",
        });
        refetch();
        setSelectedService(null);
      } else {
        const result = await addDetailingService(params);
        console.log("insertedId", result);
        setLoading(false);
        if (result.insertedId) {
          setAlert({
            active: true,
            type: "success",
            message: "Service added successfully",
          });
        }
        refetch();
        setSelectedService(null);
      }
      // setTimeout(() => {
      //   navigate("/admin/all-services");
      // }, 2000);
      handleCloseModal();
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: " Error while adding Service",
      });
      setSelectedService(null);
    }
  };

  const onError = () => {
    setAlert({
      active: true,
      type: "error",
      message: " Error while uploading image",
    });
    setIsUploading(false);
  };
  const onSuccess = (res: any) => {
    setServiceImage(res);
    setIsUploading(false);
  };
  const onUploadProgress = () => {
    setIsUploading(true);
  };

  const handleDeleteFile = () => {
    setServiceImage(null);
  };

  const onDelete = async (deleteId: any) => {
    try {
      await deleteDetailingService({ id: deleteId });
      refetch();
      setAlert({
        active: true,
        type: "success",
        message: "Successfully Deleted",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "success",
        message: "Failed to delete",
      });
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    // setEditMode(false);
    // setSelectedMenu(null);
    // setGenericImage(null);
  };

  return {
    initialValues,
    validationSchema,
    onSubmit,
    onError,
    onSuccess,
    onUploadProgress,
    isUploading,
    serviceImage,
    loading,
    setLoading,
    fileInputRef,
    isActive,
    setIsActive,
    handleDeleteFile,
    setAlert,
    // setSelectedPlugs,
    setSelectedCategory,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    id,
    isSuccess,
    serviceData,
    setServiceImage,
    onDelete,
    // selectedPlugsData,
    // plugOptions,
    // onChange,
    selectedCategoryData,
    categoryOptions,
    onCategoryChange,
    deleteId,
    setDeleteId,
    isVisible,
    setIsVisible,
    openModal,
    setOpenModal,
    handleCloseModal,
    selectedService,
    setSelectedService,
    branchId,
  };
};
