/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { AlertContext } from "controller/context/alertContext";
import { useNavigate, useParams } from "react-router-dom";
import envConfig from "../../../../env/env.json";
import * as Yup from "yup";

import { FormValues, Location, Address } from "./provider.interface";
import { fetchedOptions } from "./dummyData";
import { UserContext } from "controller/context/userContext";
import {
  AddProviderValues,
  deleteProvider,
  updateProvider,
} from "controller/services/AutoDetailing/providerServices";
import { useOneProvider, useProvidersList } from "model/queryCalls/queryCalls";
// import { useImageUpload } from "components/ ImageUploadUtility";

export const useProviders = (handleNext: any) => {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const { userType } = useContext(UserContext);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );

  const [address, setAddress] = useState<Address | null>(null);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [branchId, setBranchId] = useState<any>(null);
  const [, setIsSuccessful] = useState<boolean>(false);
  const [location, setLocation] = useState<Location | null>(null);
  const [isVisible, setIsVisible] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState();
  const [brandImage, setBrandImage] = React.useState<any>();
  const [bannerImage, setBannerImage] = React.useState<any>();
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [isBannerUploading, setIsBannerUploading] =
    React.useState<boolean>(false);
  const logoInputRef = React.useRef(null);
  const bannerInputRef = React.useRef(null);

  const navigate = useNavigate();

  const [pinCode, setPinCode] = useState<string>("");
  const [mapCenterLocation, setMapCenterLocation] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: 0,
    lng: 0,
  });
  const [isMapVisible, setIsMapVisible] = useState<boolean>(false);

  const {
    data: providerData,
    // refetch: providerRefetch,
    isSuccess,
  } = useOneProvider({ id: id });

  console.log("branchId in useProvider", branchId);

  const { refetch } = useProvidersList({ page, rowsPerPage });

  useEffect(() => {
    if (providerData) {
      setBranchId(providerData?.branchDetails._id);
      localStorage.setItem("branchId", providerData?.branchDetails._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const [initialValues] = useState<any>({
    name: "",
    description: "",
    type: "",
    isActive: true,
    contactDetails: "",
    username: "",
    password: "",
    providerIcon: null,
    providerBanner: null,
    workingHours: {
      sunday: { startTime: "", endTime: "" },
      monday: { startTime: "", endTime: "" },
      tuesday: { startTime: "", endTime: "" },
      wednesday: { startTime: "", endTime: "" },
      thursday: { startTime: "", endTime: "" },
      friday: { startTime: "", endTime: "" },
      saturday: { startTime: "", endTime: "" },
    },
  });

  const getCoordinatesFromPinCode = async (pinCode: string) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${pinCode}&key=${envConfig.MapsAPIKey}`
      );
      const data = await response.json();

      if (data.results.length > 0) {
        const location = data.results[0].geometry.location;
        setMapCenterLocation({ lat: location.lat, lng: location.lng });
        setLocation({
          type: "Point",
          coordinates: [location.lng, location.lat],
        });
        setAddress({
          address: data.results[0].formatted_address,
          location: {
            type: "Point",
            coordinates: [location.lng, location.lat],
          },
        });
        setIsMapVisible(true);
      } else {
        setIsMapVisible(true);
        setAlert({
          active: true,
          type: "error",
          message: "Invalid pin code. Please enter a valid one.",
        });
      }
    } catch (error) {
      console.error("Error fetching coordinates from pin code:", error);
      setIsMapVisible(false);
      setAlert({
        active: true,
        type: "error",
        message: "Error fetching location. Please try again later.",
      });
    }
  };

  const getAddress = async (lat: number, lng: number) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${envConfig.MapsAPIKey}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        setAddress({
          address: data.results[0].formatted_address,
          location: {
            type: "Point",
            coordinates: [lng, lat],
          },
        });
      } else {
        setAddress({
          address: "Address not found",
          location: {
            type: "Point",
            coordinates: [lng, lat],
          },
        });
      }
    } catch (error) {
      console.error("Error fetching address from coordinates:", error);
    }
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const lat = event.latLng?.lat() || 0;
    const lng = event.latLng?.lng() || 0;

    setMapCenterLocation({ lat, lng });

    setLocation({
      type: "Point",
      coordinates: [lng, lat],
    });

    getAddress(lat, lng);
  };

  const handlePinCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPinCode(e.target.value);
  };

  const handlePinCodeBlur = () => {
    if (pinCode) {
      getCoordinatesFromPinCode(pinCode);
    }
  };

  const handlePinCodeKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      getCoordinatesFromPinCode(pinCode);
    }
  };
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const mapIdsToOptions = (
    ids: string[],
    options: { value: string; label: string }[]
  ) => {
    return ids
      .filter((id) => id !== null && id !== undefined)
      .map((id) => options?.find((option) => option.value === id.toString()))
      .filter(Boolean);
  };

  // Helper function to ensure time is in 24-hour format
  const formatTo24Hour = (time: string) => {
    if (!time) return "";

    // If time includes AM/PM
    if (
      time.toLowerCase().includes("am") ||
      time.toLowerCase().includes("pm")
    ) {
      const [timeStr, period] = time.toLowerCase().split(" ");
      let [hours, minutes] = timeStr.split(":");
      let hour = parseInt(hours, 10);

      // Convert 12-hour format to 24-hour format
      if (period === "pm" && hour !== 12) {
        hour += 12;
      } else if (period === "am" && hour === 12) {
        hour = 0;
      }

      return `${hour.toString().padStart(2, "0")}:${minutes}`;
    }

    // If time is already in 24-hour format
    const [hours, minutes] = time.split(":");
    return `${hours.padStart(2, "0")}:${minutes}`;
  };

  useEffect(() => {
    const fetchOptions = () => {
      setOptions(fetchedOptions);
    };
    fetchOptions();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setRowsPerPage(+event.target.value);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter provider name"),
    description: Yup.string().required("Please enter a description"),
    contactDetails: Yup.string().required("Please enter your contact details"),
    username: Yup.string()
      .email("Please enter a valid email address")
      .required("Please enter a valid email"),
    password: Yup.string().required("Please enter a password"),
  });

  const transformWorkingHours = (workingHours: any) => {
    return daysOfWeek.map((day) => {
      const dayKey = day.toLowerCase();
      // Ensure both start and end times are in 24-hour format
      const startTime = workingHours[dayKey]?.startTime
        ? formatTo24Hour(workingHours[dayKey].startTime)
        : "";
      const endTime = workingHours[dayKey]?.endTime
        ? formatTo24Hour(workingHours[dayKey].endTime)
        : "";

      return {
        name: day,
        status: !!(startTime && endTime),
        startTime,
        endTime,
      };
    });
  };

  const onSubmit = async (values: FormValues) => {
    const transformedWorkingHours = transformWorkingHours(values.workingHours);
    setLoading(true);

    try {
      const params = {
        ...values,
        workingHours: transformedWorkingHours,
        logo: brandImage,
        banner: bannerImage,
        type: "AUTO-DETAILING",
      };
      console.log("params", params);
      const locationCoordinates = location
        ? {
            lng: location.coordinates[0],
            lat: location.coordinates[1],
          }
        : null;

      if (id) {
        const updateRes = await updateProvider({
          providerId: id,
          data: params,
          location: locationCoordinates,
          address: address?.address || "",
        });

        console.log("updateRes", updateRes);
        if (updateRes) {
          setBranchId(updateRes.branchId);
          // Refetch all necessary data
          await Promise.all([
            refetch(), // Refetch  list
          ]);
          setAlert({
            active: true,
            type: "success",
            message: "Provider updated successfully",
          });
          setIsSuccessful(true);
          handleNext();
        }
      } else {
        const result = await AddProviderValues({
          data: params,
          location: locationCoordinates,
          address: address?.address || "",
          // userType: userType,
        });
        if (result) {
          setBranchId(result.insertedId);
          console.log("branchId", branchId, result.insertedId);
          localStorage.setItem("branchId", result.insertedId);
          refetch();
          // await refetch();
          setAlert({
            active: true,
            type: "success",
            message: "Provider created successfully",
          });
          setIsSuccessful(true);
          handleNext();
        }
      }
    } catch (error: any) {
      setAlert({
        active: true,
        type: "error",
        message: error.message || "Failed to submit. Please try again.",
      });
      setIsSuccessful(false);
    } finally {
      setLoading(false);
    }
  };

  const onError = () => {
    setAlert({
      active: true,
      type: "error",
      message: " Error while uploading image",
    });
    setIsUploading(false);
    setIsBannerUploading(false);
  };
  const onLogoSuccess = (res: any) => {
    setBrandImage(res);
    setIsUploading(false);
  };
  const onLogoUploadProgress = () => {
    setIsUploading(true);
  };

  const handleDeleteLogo = () => {
    setBrandImage(null);
  };

  const onBannerSuccess = (res: any) => {
    setBannerImage(res);
    setIsBannerUploading(false);
  };
  const onBannerUploadProgress = () => {
    setIsBannerUploading(true);
  };

  const handleDeleteBanner = () => {
    setBannerImage(null);
  };

  const onDelete = async (deleteId: string) => {
    try {
      const result = await deleteProvider({ id: deleteId });
      if (result?.providerDeleteResult?.deletedCount > 0) {
        setAlert({
          active: true,
          type: "success",
          message: "Provider deleted successfully",
        });
        refetch();
      } else {
        throw new Error("Failed to delete the provider");
      }
    } catch (error) {
      console.error("Delete provider provider error:", error);
      setAlert({
        active: true,
        type: "error",
        message: "Failed to delete provider. Please try again.",
      });
    }
  };

  const onToggleActive = async (providerId: string, newIsActive: boolean) => {
    try {
      // await UpdateOneProviderStatus({
      //   id: providerId,
      //   isActive: !newIsActive,
      //   userType: userType,
      // });
      // refetch();

      setAlert({
        active: true,
        type: "success",
        message: "Provider status updated successfully",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to update provider status",
      });
    }
  };

  return {
    page,
    rowsPerPage,
    navigate,
    userType,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    loading,
    setLoading,
    initialValues,
    validationSchema,
    onSubmit,
    options,
    isActive,
    setIsActive,
    location,
    setLocation,
    branchId,
    setAddress,
    address,
    onToggleActive,
    mapIdsToOptions,
    mapCenterLocation,
    isMapVisible,
    handleMapClick,
    handlePinCodeChange,
    handlePinCodeBlur,
    handlePinCodeKeyDown,
    daysOfWeek,
    pinCode,
    deleteId,
    setDeleteId,
    isVisible,
    setIsVisible,
    id,
    isSuccess,
    providerData,
    formatTo24Hour,
    setBranchId,
    setMapCenterLocation,
    bannerImage,
    setBannerImage,
    brandImage,
    setBrandImage,
    isUploading,
    isBannerUploading,
    onError,
    onLogoSuccess,
    onLogoUploadProgress,
    handleDeleteLogo,
    onBannerSuccess,
    onBannerUploadProgress,
    handleDeleteBanner,
    logoInputRef,
    bannerInputRef,
  };
};
