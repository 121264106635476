import {
  DeleteServiceCategory,
  addServiceCategory,
  updateServiceCategory,
} from "controller/services/AutoDetailing/categoryServices";
import React, { useContext } from "react";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { AlertContext } from "controller/context/alertContext";
import { formatImage } from "controller/common/formatImage";
import {
  useDetailingCategories,
  useOneDetailingCategory,
} from "model/queryCalls/queryCalls";

export const useCategories = () => {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [categoryImage, setCategoryImage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const fileInputRef = React.useRef(null); // Use ref for file input
  const [isActive, setIsActive] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isVisible, setIsVisible] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState();

  const { data: categoryData, isSuccess } = useOneDetailingCategory({ id });

  const { refetch } = useDetailingCategories({ page, rowsPerPage });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
  };

  const initialValues = {
    name: "",
    image: "",
    // order: 1,
    isActive: true,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    // image: Yup.mixed().required("Please upload an Image"),
    // order: Yup.number().required("Please add Category order"),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const params = {
        values,
        isActive,
        image: categoryImage
          ? formatImage({ file: categoryImage, source: "imageKit" })
          : null,
      };
      if (id) {
        await updateServiceCategory({ id, ...params });
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Category Updated successfully",
        });
      } else {
        await addServiceCategory(params);
        setLoading(false);
        setAlert({
          active: true,
          type: "success",
          message: "Category added successfully",
        });
      }
      setTimeout(() => {
        navigate("/admin/all-categories");
      }, 2000);
    } catch (error) {
      setLoading(false);
      setAlert({
        active: true,
        type: "error",
        message: " Error while adding Category",
      });
    }
  };

  const onError = () => {
    setAlert({
      active: true,
      type: "error",
      message: " Error while uploading image",
    });
    setIsUploading(false);
  };
  const onSuccess = (res: any) => {
    setCategoryImage(res);
    setIsUploading(false);
  };
  const onUploadProgress = () => {
    setIsUploading(true);
  };

  const handleDeleteFile = () => {
    setCategoryImage(null);
  };

  const onDelete = async (deleteId: any) => {
    try {
      await DeleteServiceCategory({ id: deleteId });
      refetch();
      setAlert({
        active: true,
        type: "success",
        message: "Successfully Deleted",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "success",
        message: "Failed to delete",
      });
    }
  };

  return {
    initialValues,
    validationSchema,
    onSubmit,
    onError,
    onSuccess,
    onUploadProgress,
    isUploading,
    categoryImage,
    loading,
    setLoading,
    fileInputRef,
    isActive,
    setIsActive,
    handleDeleteFile,
    setAlert,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    id,
    isSuccess,
    categoryData,
    setCategoryImage,
    onDelete,
    deleteId,
    setDeleteId,
    isVisible,
    setIsVisible,
  };
};
