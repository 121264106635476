import { useQuery } from "react-query";
import { useContext } from "react";
import { UserContext } from "controller/context/userContext";
import { getBrands, getOneBrand } from "controller/services/brandServices";
import {
  getServiceCategories,
  getOneServiceCategory,
} from "controller/services/AutoDetailing/categoryServices";

import { getOnePlug, getPlugs } from "controller/services/plugServices";
import {
  getOneVehicle,
  getVehicles,
} from "controller/services/vehicleServices";

import {
  getOneDetailingService,
  getDetailingServices,
} from "controller/services/AutoDetailing/detailingServices";

import {
  getAmenities,
  getOneAmenity,
} from "controller/services/amenityServices";
import { getFAQs, getOneFAQ } from "controller/services/FAQServices";
import {
  getOnePopularRoute,
  getPopularRoutes,
} from "controller/services/popularRouteServices";
import { getAppSettings } from "controller/services/maintenanceServices";
import {
  getCustomers,
  getOneCustomer,
  getOneUser,
  getUsers,
} from "controller/services/userServices";
import { getCms, getOneCms } from "controller/services/CMSServices";
import {
  getEVStations,
  getOneEVStation,
} from "controller/services/evStationServices";
import {
  getChargingHistory,
  getOneChargingHistory,
} from "controller/services/chargingHistoryServices";
import {
  getOneProvider,
  getProviders,
} from "controller/services/AutoDetailing/providerServices";

export const useBrand = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["brandsData", user, page, rowsPerPage], () =>
    getBrands({ page, rowsPerPage })
  );
};

export const useOneBrand = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneBrand", user, { id }], () => getOneBrand({ id }));
};

export const usePlugType = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["plugsData", user, page, rowsPerPage], () =>
    getPlugs({ page, rowsPerPage })
  );
};

export const useOnePlug = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["onePlug", user, { id }], () => getOnePlug({ id }));
};

export const useVehicle = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["vehiclesData", user, page, rowsPerPage], () =>
    getVehicles({ page, rowsPerPage })
  );
};

export const useOneVehicle = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneVehicle", user, { id }], () => getOneVehicle({ id }));
};

export const useAmenity = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["amenitiesData", user, page, rowsPerPage], () =>
    getAmenities({ page, rowsPerPage })
  );
};

export const useOneAmenity = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneAmenity", user, { id }], () => getOneAmenity({ id }));
};

export const useFAQ = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["FAQsData", user, page, rowsPerPage], () =>
    getFAQs({ page, rowsPerPage })
  );
};

export const useOneFAQ = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneFAQ", user, { id }], () => getOneFAQ({ id }));
};

export const usePopularRoute = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["popularRoute", user, page, rowsPerPage], () =>
    getPopularRoutes({ page, rowsPerPage })
  );
};

export const useOnePopularRoute = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["onePopularRoute", user, { id }], () =>
    getOnePopularRoute({ id })
  );
};

export const useAppSettings = ({ type }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["appSettings", user, { type }], () =>
    getAppSettings({ type })
  );
};

//users
export const useUsersList = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["usersData", user, page, rowsPerPage], () =>
    getUsers({ page, rowsPerPage })
  );
};

export const useOneUser = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneUser", user, { id }], () => getOneUser({ id }));
};

export const useCustomers = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["customerData", user, page, rowsPerPage], () =>
    getCustomers({ page, rowsPerPage })
  );
};

export const useOneCustomer = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneCustomer", user, { id }], () => getOneCustomer({ id }));
};

export const useCms = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["cmsData", user, page, rowsPerPage], () =>
    getCms({ page, rowsPerPage })
  );
};

export const useOneCms = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["onePoster", user, { id }], () => getOneCms({ id }));
};

export const useStations = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["stationsData", user, page, rowsPerPage], () =>
    getEVStations({ page, rowsPerPage })
  );
};

export const useOneStation = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneStation", user, { id }], () => getOneEVStation({ id }));
};

export const useChargingHistories = ({
  page,
  rowsPerPage,
  stationId,
  customerId,
}: any) => {
  const { user } = useContext(UserContext);
  return useQuery(
    ["historyData", user, page, rowsPerPage, stationId, customerId],
    () => getChargingHistory({ page, rowsPerPage, stationId, customerId })
  );
};

export const useOneChargeHistory = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneHistory", user, { id }], () =>
    getOneChargingHistory({ id })
  );
};

export const useDetailingCategories = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["detailingCategoriesData", user, page, rowsPerPage], () =>
    getServiceCategories({ page, rowsPerPage })
  );
};

export const useOneDetailingCategory = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneDetailingCategory", user, { id }], () =>
    getOneServiceCategory({ id })
  );
};

export const useDetailingService = ({ page, rowsPerPage, branchId }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(
    ["DetailingServiceData", user, page, rowsPerPage, branchId],
    () => getDetailingServices({ page, rowsPerPage, branchId })
  );
};

export const useOneDetailingService = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["oneDetailingService", user, { id }], () =>
    getOneDetailingService({ id })
  );
};

export const useProvidersList = ({ page, rowsPerPage }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["providerData", user, page, rowsPerPage], () =>
    getProviders({ page, rowsPerPage })
  );
};

export const useOneProvider = ({ id }: any) => {
  const { user } = useContext(UserContext);
  return useQuery(["OneProvider", user, { id }], () => getOneProvider({ id }));
};
