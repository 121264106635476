/*  */
/*  services */
/*  */

import { app, getDBInstance, ObjectId } from "../dbServices";

export const addDetailingService = async ({
  values,
  isActive,
  image,
  category,
  branchId,
}: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!values || !branchId) {
    return;
  }

  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId);
  }

  const result = await getDBInstance()
    .collection("autoDetailingServices")
    .insertOne({
      name: {
        en: values.name,
      },
      description: values.description,
      startingPrice: values.startingPrice,
      isActive,
      image: image ? image : {},
      dateCreated: new Date(),
      dateModified: null,
      order: values.order,
      category: category,
      branchId: branchId,
    });
  return result;
};

export const updateDetailingService = async ({
  id,
  values,
  isActive,
  image,
  category,
  branchId,
}: // plugs,
any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id || !values || !branchId) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId);
  }

  const result = await getDBInstance()
    .collection("autoDetailingServices")
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          name: {
            en: values.name,
          },
          description: values.description,
          startingPrice: values.startingPrice,
          isActive,
          image: image ? image : {},
          dateModified: new Date(),
          order: values.order,
          category: category,
          branchId: branchId,
          // plugs: plugs,
          // efficiency: values.efficiency,
          // range: values.range,
        },
      }
    );

  return result;
};

export const getDetailingServices = async ({
  page = 0,
  rowsPerPage = 10,
  branchId,
}: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!branchId) {
    return;
  }

  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId);
  }
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  const totalDocumentCount = await getDBInstance()
    .collection("autoDetailingServices")
    .count({});

  const result = await getDBInstance()
    .collection("autoDetailingServices")
    .aggregate([
      {
        $match: { branchId: branchId },
      },
      {
        $lookup: {
          from: "autoDetailingCategories",
          localField: "category",
          foreignField: "_id",
          as: "categoryDetails",
        },
      },
      {
        $unwind: "$categoryDetails",
      },
      {
        $project: {
          _id: 1,
          name: 1,
          isActive: 1,
          description: 1,
          startingPrice: 1,
          dateCreated: 1,
          dateModified: 1,
          order: 1,
          image: {
            url: 1,
            name: 1,
          },
          category: "$categoryDetails.name",
          // efficiency: 1,
          // range: 1,
        },
      },
      { $skip: skip },
      { $limit: limit },
      {
        $sort: {
          order: 1,
        },
      },
    ]);

  return { data: result, totalDocumentCount };
};

export const getOneDetailingService = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("autoDetailingServices")
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $lookup: {
          from: "autoDetailingCategories",
          localField: "category",
          foreignField: "_id",
          as: "categoryDetails",
        },
      },
      {
        $unwind: "$categoryDetails",
      },
      {
        $project: {
          _id: 1,
          name: 1,
          isActive: 1,
          description: 1,
          startingPrice: 1,
          dateCreated: 1,
          dateModified: 1,
          order: 1,
          category: 1,
          image: {
            url: 1,
            name: 1,
          },
          categoryName: "$categoryDetails.name",
          // efficiency: 1,
          // range: 1,
        },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);
  return result[0];
};

export const deleteDetailingService = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection("autoDetailingServices")
    .deleteOne({ _id: id });

  return result;
};
