// import { providerUserRegister } from "../authServices";
import { branchUserRegister } from "../authServices";
import { app, getDBInstance, ObjectId, providerApp } from "../dbServices";
// import { User } from "realm-web";

export const AddProviderValues = async ({ data, location, address }: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!data) {
    return;
  }

  const existingProvider = await getDBInstance()
    .collection("branches")
    .findOne({ username: data.username });
  if (existingProvider) {
    throw new Error("A provider with this email already exists.");
  }
  // const selectedApp = userType === "branch" ? providerApp : app;
  // const categoryObjectIds = data.category.map((id: string) => new ObjectId(id));
  // const cuisineObjectIds = data.cuisine.map((id: string) => new ObjectId(id));

  const result = await getDBInstance()
    .collection("providers")
    .insertOne({
      name: { en: data.name },
      description: data.description,
      type: data.type,
      metaData: {
        createdAt: new Date(),
        createdBy: data.createdBy,
        modifiedAt: null,
        modifiedBy: null,
        isActive: data.isActive,
      },
    });

  const branchResult = await getDBInstance()
    .collection("branches")
    .insertOne({
      providerId: result.insertedId,
      workingHours: data.workingHours,
      type: data.type,
      contactDetails: data.contactDetails,
      username: data.username,
      password: data.password,
      logo: data.logo,
      banner: data.banner,
      address: {
        location: { type: "Point", coordinates: [location.lng, location.lat] },
        address: address,
      },
      metaData: {
        createdAt: new Date(),
        createdBy: data.createdBy,
        modifiedAt: null,
        modifiedBy: null,
        isActive: data.isActive,
      },
    });

  try {
    await providerApp.emailPasswordAuth.registerUser({
      email: data.username,
      password: data.password,
    });
    console.log("Realm user created successfully for branch.");

    const registerResult = await branchUserRegister({
      data: data,
      branchId: branchResult.insertedId,
      // currentApp: selectedApp,
    });
    console.log("Additional user registration success:", registerResult);
  } catch (error: any) {
    console.error("Failed to create Realm user:", error.message);
    if (error.message.includes("name already in use")) {
      throw new Error(
        "A user with this email already exists in the branch app."
      );
    } else {
      throw new Error(
        `Failed to create Realm user for branch: ${error.message}`
      );
    }
  }

  return branchResult;
};

export const getProviders = async ({ page = 0, rowsPerPage = 10 }: any) => {
  const skip = page * rowsPerPage;
  const limit = rowsPerPage;

  if (!app || !app.currentUser) {
    return;
  }

  const totalDocumentCount = await getDBInstance()
    .collection("providers")
    .count({});

  const result = await getDBInstance()
    .collection("providers")
    .aggregate([
      {
        $lookup: {
          from: "branches",
          localField: "_id",
          foreignField: "providerId",
          as: "branchDetails",
        },
      },
      {
        $unwind: "$branchDetails",
      },
      {
        $sort: { _id: -1 },
      },
      {
        $skip: skip,
      },
      {
        $limit: limit,
      },
    ]);

  return {
    data: result,
    totalDocumentCount,
  };
};

export const getOneProvider = async ({ id }: any) => {
  try {
    if (!app || !app.currentUser) {
      return;
    }

    if (!id) {
      return;
    }

    if (typeof id === "string") {
      id = new ObjectId(id);
    }

    const result = await getDBInstance()
      .collection("providers")
      .aggregate([
        {
          $match: {
            _id: id,
          },
        },
        {
          $lookup: {
            from: "branches",
            localField: "_id",
            foreignField: "providerId",
            as: "branchDetails",
          },
        },
        {
          $unwind: "$branchDetails",
        },
        {
          $lookup: {
            from: "autoDetailingServices",
            localField: "branchDetails._id",
            foreignField: "branchId",
            as: "branchDetails.serviceDetails",
          },
        },
        // {
        //   $unwind: "$branchDetails.serviceDetails",
        // },
      ]);
    return result.length ? result[0] : null;
  } catch (error) {
    console.error("Error fetching provider:", error);
    return null;
  }
};

export const deleteProvider = async ({ id }: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!id) {
    return;
  }

  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  // Delete provider document
  const providerDeleteResult = await getDBInstance()
    .collection("providers")
    .deleteOne({
      _id: id,
    });

  // Delete branch, modifiers, menu, and businessUsers associated with branchIds
  const branchDeleteResult = await getDBInstance()
    .collection("branches")
    .deleteMany({
      providerId: id,
    });

  return {
    providerDeleteResult,
    branchDeleteResult,
  };
};

export const updateProvider = async ({
  providerId,
  data,
  location,
  address,
}: any) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!data) {
    return;
  }

  if (!providerId) {
    return;
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  try {
    // Update provider information
    const providerUpdateResult = await getDBInstance()
      .collection("providers")
      .updateOne(
        { _id: providerId },
        {
          $set: {
            name: { en: data.name },
            description: data.description,
            type: data.type,
            metaData: {
              createdAt: new Date(),
              createdBy: data.createdBy,
              modifiedAt: new Date(),
              modifiedBy: data.modifiedBy,
              isActive: data.isActive,
            },
          },
        }
      );

    // Prepare the query for branch update
    const query: any = {
      workingHours: data.workingHours,
      type: data.type,
      contactDetails: data.contactDetails,
      username: data.username,
      password: data.password,
      logo: data.logo,
      banner: data.banner,
      address: {
        address: address,
        // Only update location if it exists, otherwise keep existing location
        ...(location && {
          location: {
            type: "Point",
            coordinates: [location.lng, location.lat],
          },
        }),
      },
      metaData: {
        createdAt: new Date(),
        createdBy: data.createdBy,
        modifiedAt: new Date(),
        modifiedBy: data.modifiedBy,
        isActive: data.isActive,
      },
    };

    // Update the branch information
    const branchUpdateResult = await getDBInstance()
      .collection("branches")
      .updateOne(
        { providerId: providerId },
        {
          $set: query,
        }
      );

    // Retrieve the updated branch information
    const updatedBranch = await getDBInstance()
      .collection("branches")
      .findOne({ providerId: providerId }, { projection: { _id: 1 } });

    return {
      providerUpdateResult,
      branchUpdateResult,
      branchId: updatedBranch?._id,
    };
  } catch (error) {
    console.error("Error updating provider:", error);
    return null;
  }
};

export const UpdateOneProviderStatus = async ({ id, isActive }: any) => {
  if (!id) {
    return;
  }

  if (typeof id === "string") {
    id = new ObjectId(id);
  }

  const db = getDBInstance();

  try {
    // Update `isActive` status in the `provider` collection
    const providerUpdateResult = await db.collection("providers").updateOne(
      {
        _id: id,
      },
      {
        $set: {
          "metaData.isActive": isActive,
        },
      }
    );

    // Update `isActive` status in the `branch` collection
    const branchUpdateResult = await db.collection("branches").updateMany(
      {
        providerId: id,
      },
      {
        $set: {
          "metaData.isActive": isActive,
        },
      }
    );

    return {
      providerUpdateResult,
      branchUpdateResult,
    };
  } catch (error) {
    console.error("Error updating provider and branch status:", error);
    return null;
  }
};
