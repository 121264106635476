/*  */
/* Auth services */
/*  */

import { app } from "./dbServices";

export const userRegister = async ({ data }: any) => {
  if (!data) {
    return;
  }
  const result = await app.currentUser.callFunction("register", {
    data,
  });

  return result;
};

export const sendEmailAfterLogin = async ({ toEmail }: any) => {
  if (!toEmail) {
    return;
  }

  const result = await app.currentUser.callFunction("reSendEmail", {
    toEmail,
  });
  return result;
};

export const verifyOTP = async ({ email, submittedOtp }: any) => {
  if (!email || !submittedOtp) {
    return;
  }

  const result = await app.currentUser.callFunction("verifyOTP", {
    email,
    submittedOtp,
  });
  return result;
};

export const branchUserRegister = async ({ data, branchId }: any) => {
  if (!data) {
    throw new Error("No data provided for branch user registration.");
  }

  try {
    const result = await app.currentUser.callFunction("branchRegister", {
      data,
      branchId,
    });
    return result;
  } catch (error) {
    throw new Error(`Failed to register branch user: ${error}`);
  }
};
