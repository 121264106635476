import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  TablePagination,
} from "@mui/material";
import Card from "components/card";
import { useDetailingService } from "model/queryCalls/queryCalls";
import { MdDelete, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { useServices } from "./useService";
import { BsFileEarmarkImage } from "react-icons/bs";
import ModalContainer from "components/modal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Switch from "components/switch";
import { MdClose, MdFileUpload } from "react-icons/md";
import { IKContext, IKUpload } from "imagekitio-react";
import {
  authenticator,
  publicKey,
  urlEndpoint,
} from "controller/common/imageKit/imageKit";
import React from "react";
import Select from "react-select";
import "tailwindcss/tailwind.css";

const Services = () => {
  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    deleteId,
    setDeleteId,
    isVisible,
    setIsVisible,
    openModal,
    setOpenModal,
    handleCloseModal,
    initialValues,
    validationSchema,
    onSubmit,
    onError,
    onSuccess,
    onUploadProgress,
    isUploading,
    serviceImage,
    loading,
    fileInputRef,
    isActive,
    setIsActive,
    handleDeleteFile,
    setAlert,
    id,
    isSuccess,
    serviceData,
    setServiceImage,
    setSelectedCategory,
    selectedCategoryData,
    categoryOptions,
    onCategoryChange,
    setSelectedService,
    branchId,
  } = useServices();
  const navigate = useNavigate();
  const { data: servicesData, status } = useDetailingService({
    page,
    rowsPerPage,
    branchId: branchId,
  });

  // console.log("branchId", branchId);

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="col-span-5 my-10 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
        <Card extra={"w-full h-full p-3"}>
          <div className="relative mb-3 items-center justify-between py-10 lg:px-10">
            {status === "loading" ? (
              <Box>
                <Skeleton sx={{ height: 190 }} />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </Box>
            ) : status === "success" && servicesData?.data?.length !== 0 ? (
              <>
                <div className="flex justify-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenModal(true)}
                    className="mb-4"
                  >
                    Add New Service
                  </Button>
                </div>
                <Table className="w-full min-w-full table-auto">
                  <Thead>
                    <Tr>
                      <Th className="border-b border-gray-100 px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Order
                        </p>
                      </Th>
                      <Th className="border-b border-gray-100 px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Image
                        </p>
                      </Th>
                      <Th className="border-b border-gray-100 px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Name
                        </p>
                      </Th>
                      <Th className="border-b border-gray-100 px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Category
                        </p>
                      </Th>
                      {/* <Th className="border-gray-100 border-b px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Plugs
                        </p>
                      </Th> */}
                      {/* <Th className="border-gray-100 border-b px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Efficiency
                        </p>
                      </Th>
                      <Th className="border-gray-100 border-b px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Range
                        </p>
                      </Th> */}
                      <Th className="border-b border-gray-100 px-5 py-3 text-left">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Is Active
                        </p>
                      </Th>
                      <Th className="border-b border-gray-100 px-5 py-3 text-center">
                        <p className="text-blue-gray-400 text-[13px] font-bold uppercase">
                          Actions
                        </p>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {servicesData?.data.map((data: any) => (
                      <Tr
                        key={data._id}
                        className=" hover:cursor-pointer hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20"
                        onClick={() =>
                          navigate(`/admin/service-details/${data._id}`)
                        }
                      >
                        <Td className="border-b border-gray-100 px-5 py-3">
                          <p className="text-left font-semibold">
                            {data.order}
                          </p>
                        </Td>
                        <Td className="border-b border-gray-100  px-5 py-3">
                          {data?.image?.url ? (
                            <img
                              src={data?.image.url}
                              alt={data?.name.en}
                              className="h-[50px] w-[50px]"
                            />
                          ) : (
                            <BsFileEarmarkImage className="h-[40px] w-[40px] text-gray-800" />
                          )}
                        </Td>
                        <Td className="border-b border-gray-100  px-5 py-3">
                          <p className="font-semibold">{data.name.en}</p>
                        </Td>
                        <Td className="border-b border-gray-100  px-5 py-3">
                          <p className="font-semibold">{data.category?.en}</p>
                        </Td>
                        {/* <Td className="border-gray-100 border-b  px-5 py-3">
                            <p className="font-semibold">
                              {data?.plugs &&
                                data?.plugs.map((plug: any, index: any) => (
                                  <span>
                                    {index !== data.plugs.length - 1
                                      ? plug.en + ", "
                                      : plug.en}
                                  </span>
                                ))}
                            </p>
                          </Td> */}
                        {/* <Td className="border-gray-100 border-b  px-5 py-3">
                            <p className="font-semibold">
                              {data.efficiency}kWh/100mi
                            </p>
                          </Td>
                          <Td className="border-gray-100 border-b  px-5 py-3">
                            <p className="font-semibold">{data.range}mi</p>
                          </Td> */}
                        <Td className="border-b border-gray-100  px-5 py-3">
                          <p>
                            {data.isActive ? (
                              <Chip
                                label="Active"
                                color="success"
                                size="small"
                              />
                            ) : (
                              <Chip
                                label="Inactive"
                                color="error"
                                size="small"
                              />
                            )}
                          </p>
                        </Td>
                        <Td className="border-b border-gray-100  px-5 py-3 text-center">
                          <div className="flex items-center gap-2 xl:justify-center">
                            <button
                              onClick={(
                                event: React.MouseEvent<
                                  HTMLButtonElement,
                                  MouseEvent
                                >
                              ) => {
                                event.stopPropagation();
                                setSelectedService(data?._id);
                                // navigate(`/admin/edit-service/${data._id}`);
                                setOpenModal(true);
                              }}
                              className="z-50 text-blue-500 hover:underline"
                            >
                              <MdEdit className="h-5 w-5" />
                            </button>
                            <button
                              onClick={(
                                event: React.MouseEvent<
                                  HTMLButtonElement,
                                  MouseEvent
                                >
                              ) => {
                                event.stopPropagation();
                                setIsVisible(!isVisible);
                                setDeleteId(data?._id);
                              }}
                              className="z-50 text-red-500 hover:underline"
                            >
                              <MdDelete className="h-5 w-5" />
                            </button>
                          </div>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  className="text-sm font-bold text-navy-700 dark:text-white"
                  count={servicesData?.totalDocumentCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              // <div className="flex w-full flex-col items-center justify-center">
              //   <BsDatabaseFillX className="h-16 w-16 text-gray-500" />
              //   <p className="mt-3 text-sm font-bold text-gray-800">No Data</p>
              // </div>
              <Card className="flex flex-col items-center justify-center py-10">
                <p className="mb-5 text-gray-600">No services available.</p>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenModal(true)}
                  className="mt-5"
                >
                  Add New Services
                </Button>
              </Card>
            )}
          </div>
        </Card>
      </div>
      <ModalContainer
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        deleteId={deleteId}
        confirm={onDelete}
      />
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{"Add New Service"}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ setValues }: any) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              React.useEffect(() => {
                if (id && serviceData) {
                  setValues({
                    name: serviceData.name.en,
                    description: serviceData.description,
                    image: "",
                    order: serviceData.order,
                    isActive: serviceData.isActive,
                    startingPrice: serviceData.startingPrice,
                    // efficiency: serviceData.efficiency,
                    // range: serviceData.range,
                  });
                  setIsActive(serviceData?.isActive);
                  setServiceImage(serviceData?.image);
                  setSelectedCategory(serviceData?.category);
                  // setSelectedPlugs(serviceData?.plugs);
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
              }, [id, isSuccess, serviceData]);
              return (
                <Form>
                  <div className="mb-3">
                    <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                      Name*
                    </label>
                    <Field
                      type="name"
                      name="name"
                      className={
                        "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                      }
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="mb-3">
                    <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                      Category*
                    </label>
                    <Select
                      name="brands"
                      options={categoryOptions}
                      className="basic-single-select mt-2"
                      classNamePrefix="select"
                      value={selectedCategoryData}
                      onChange={onCategoryChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                      Description*
                    </label>
                    <Field
                      type="textarea"
                      component="textarea"
                      as="textarea"
                      name="description"
                      className={
                        "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                      }
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="mb-3">
                    <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                      Starting Price*
                    </label>
                    <Field
                      type="number"
                      name="startingPrice"
                      min="0"
                      className={
                        "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                      }
                    />
                    <ErrorMessage
                      name="startingPrice"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <div className="mb-3 grid grid-cols-1 gap-10 md:grid-cols-2">
                    <div className="flex-column mb-3 mt-4 flex-1 items-center gap-3">
                      <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                        Image
                      </label>
                      {/* <Field
                      type=""
                      name="name"
                      className={
                        "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                      }
                    /> */}
                      <Card className="grid h-full w-full grid-cols-1 gap-3 rounded-[20px] bg-white bg-clip-border p-3 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none 2xl:grid-cols-11">
                        <div className="col-span-5 h-full w-full rounded-xl bg-lightPrimary dark:!bg-navy-700 2xl:col-span-6">
                          {serviceImage && serviceImage?.url ? (
                            <>
                              <div className="relative flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700 lg:pb-0">
                                <div className="relative h-[120px] flex-shrink-0">
                                  <img
                                    src={serviceImage?.url}
                                    alt={serviceImage?.name}
                                    className="h-[120px] w-[120px]"
                                  />
                                </div>
                                <button
                                  className="absolute right-1 top-1"
                                  onClick={() => handleDeleteFile()}
                                >
                                  <MdClose className="h-6 w-6" />
                                </button>
                              </div>
                            </>
                          ) : (
                            <button
                              onClick={() => {
                                fileInputRef.current.click();
                              }}
                              type="button"
                              className="flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700 lg:pb-0"
                            >
                              {isUploading ? (
                                <CircularProgress className="h-[80px] w-[80px]" />
                              ) : (
                                <MdFileUpload className="text-[80px] text-brand-500 dark:text-white" />
                              )}
                              <h4 className="text-xl font-bold text-brand-500 dark:text-white">
                                Upload Files
                              </h4>
                              <p className="mt-2 text-sm font-medium text-gray-600">
                                {isUploading
                                  ? `Uploading`
                                  : ` Click to Upload Image File`}
                              </p>
                            </button>
                          )}
                          <div className="mb-3" id="ikUploadWrapper">
                            <IKContext
                              publicKey={publicKey}
                              urlEndpoint={urlEndpoint}
                              authenticator={authenticator}
                            >
                              <IKUpload
                                onError={onError}
                                onSuccess={onSuccess}
                                onUploadStart={onUploadProgress}
                                folder={"/cm-services"}
                                tags={["cm-services"]}
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                accept="image/*"
                                onChange={(e) => {
                                  const selectedFile = e.target.files[0];
                                  if (selectedFile) {
                                    if (
                                      !selectedFile.type.startsWith("image/")
                                    ) {
                                      setAlert({
                                        active: true,
                                        type: "error",
                                        message: "Please select an image file.",
                                      });
                                      e.target.value = null;
                                    }
                                  }
                                }}
                              />
                            </IKContext>
                          </div>
                        </div>
                      </Card>
                      {/* <ErrorMessage
                        name="image"
                        component="div"
                        className="text-red-500"
                      /> */}
                    </div>
                    <div className="mb-3 mt-4 flex flex-1 items-center gap-3">
                      <label
                        htmlFor="checkbox8"
                        className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                      >
                        Is Active
                      </label>
                      <Switch
                        id="switch8"
                        checked={isActive}
                        onChange={() => setIsActive(!isActive)}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                      Order*
                    </label>
                    <Field
                      type="number"
                      name="order"
                      className={
                        "mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                      }
                      min="1"
                    />
                    <ErrorMessage
                      name="order"
                      component="div"
                      className="text-red-500"
                    />
                  </div>

                  <button
                    className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    // onClick={() => navigate("/auth")}
                    type="submit"
                  >
                    {loading ? `Saving...` : `Save`}
                  </button>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            color="secondary"
            disabled={loading}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Services;
