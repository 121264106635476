import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "components/card";
import { useProviders } from "./useProviders";
// import AddModifier from "../modifiers/addModifier";
import ProviderForm from "./ProviderForm/index";
// import Menu from "./menu/Menu";
import { useNavigate } from "react-router-dom";
import Services from "../services";

const AddProvider = () => {
  // const { id } = useParams<{ id?: string }>();
  // const isEditMode = !!id;

  const isStepSkipped = (step: number) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const { branchId, handleMapClick, id } = useProviders(handleNext);

  const isEditMode = !!id;
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  // const [mapCenter] = useState({ lat: 10.8505, lng: 76.2711 });
  const navigate = useNavigate();

  const steps = ["Add Provider", "Add Services"];

  console.log("branchId in provider", branchId);

  const handleFinish = () => {
    navigate(`/admin/all-providers`);
  };

  const handleStepClick = (step: number) => {
    if (isEditMode) {
      setActiveStep(step);
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <ProviderForm
            handleMapClick={handleMapClick}
            handleNext={handleNext}
          />
        );
      case 1:
        return <Services />;

      default:
        return <div>Unknown Step</div>;
    }
  };

  return (
    <Box sx={{ width: "100%", mb: 10 }}>
      <Stepper activeStep={activeStep} sx={{ mt: 4 }}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              onClick={() => handleStepClick(index)}
              style={{ cursor: isEditMode ? "pointer" : "default" }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you're finished
          </Typography>
          <Button onClick={handleFinish}>Finish</Button>
        </>
      ) : (
        <>
          <Card extra={"w-full h-full mt-4 mb-4"}>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}></Box>
            <Box sx={{ mt: 2, mb: 1 }}>{renderStepContent(activeStep)}</Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 6 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleFinish}>
                {activeStep === steps.length - 1 ? "Finish" : ""}
              </Button>
            </Box>
          </Card>
        </>
      )}
    </Box>
  );
};

export default AddProvider;
